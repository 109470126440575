<template>
  <div>
    <DropDown v-model="item" :items="list" />
  </div>
</template>

<script>
import DropDown from "@/modules/UiKit/components/DropDown/DropDown";
export default {
  name: "DropDownPage",
  mixins: [],
  props: {},
  components: { DropDown },
  data() {
    return {
      item: {
        id: 1,
        name: "TEST1"
      },
      list: [
        {
          id: 1,
          name: "TEST1"
        },
        {
          id: 2,
          name: "TEST2"
        },
        {
          id: 3,
          name: "TEST3"
        }
      ]
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
